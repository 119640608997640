import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';

let config = {
    apiKey: "AIzaSyDsilZPxlMbNmHf0XTgSXP1tLt-YGFf7RM",
    authDomain: "pawinhandopenproject.firebaseapp.com",
    databaseURL: "https://pawinhandopenproject.firebaseio.com",
    projectId: "pawinhandopenproject",
    storageBucket: "pawinhandopenproject.appspot.com",
    messagingSenderId: "130490236669",
    appId: "1:130490236669:web:e843a3a57cd9f706a74894",
    measurementId: "G-BLPK8M29CP"
};

firebase.initializeApp(config);

export default firebase.database();