<template>
    <div class="container-fluid h-custom vh-100">
        <div class="w-100 d-flex justify-content-center align-items-center h-100">
            <!-- <div class="col-md-9 col-lg-6 col-xl-5">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                class="img-fluid" alt="Sample image">
            </div> -->
            <div class="col-md-8 col-lg-6 col-xl-4" style="max-width : 550px;">
                <div class="login-container">
                    <div class="mb-5 text-left">
                        <img :src="require('@/assets/pawinhand_logo_v2.png')" style="width: 180px;"/>
                    </div>

                    <div class="mb-5 text-left">
                        <h4 class="mb-3 fw-400">유기동물 보호소 관리의 시작,</h4>
                        <h4 class="fw-600">포인핸드 보호소 관리 시스템</h4>
                    </div>
                    <!-- Email input -->
                    <div class="form-outline mb-4">
                        <p class="text-left">로그인 정보를 입력하세요.</p>

                        <input type="email" id="form3Example3" class="form-control form-control-lg" ref="email"
                        placeholder="이메일" v-model="user.user_device_number" @keyup.enter="handleLogin()"/>
                    </div>

                    <!-- Password input -->
                    <div class="form-outline mb-3">
                        <input type="password" id="form3Example4" class="form-control form-control-lg" ref="password"
                        placeholder="비밀번호" v-model="user.user_pw" @keyup.enter="handleLogin()"/>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <!-- Checkbox -->
                        <div class="form-check mb-0">
                        <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3" v-model="saveEmail"/>
                        <label class="form-check-label fs-r-16" for="form2Example3">
                            이메일 기억하기
                        </label>
                        </div>
                        <a href="http://pf.kakao.com/_vxaxbUxl" class="fc-gray fs-r-16" style="text-decoration : none;">비밀번호를 잊으셨나요?</a>       
                    </div>

                    <div class="text-center text-lg-start mt-4 pt-2">
                        <button type="button" class="btn btn-orange btn-lg"
                        style="width: 100%; padding-top:8px !important;padding-bottom:8px !important" @click="handleLogin()">로그인</button>
                        <p class="small mt-3 pt-1 mb-0 text-center fw500" style="color:#a9a9a9 !important; cursor:pointer" @click="redirectUrl()"> 신규가입 | 계정문의</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
    name : "login",
    data(){
        return {
            user : {
                user_device_number : "",
                user_pw : "",
            },

            remember: false,
            fetchUser: true,
            staySignedIn: false,
            errors: {},
            saveEmail: false,
        }
    },
    methods : {
        loginToServer(){
            let me = this;

            // const formData = new FormData();
            // formData.append("user", this.$root.convertFormData(this.user));

            console.log(this.user);

            this.axios.post('/api/login', this.user)
                .then(function (res) {
                    console.log(res);

                    if(res.status === 200){
                        me.$root.showToastMsg('','알림','로그인 되었습니다.','default');                    
                    }
                }).catch(function (error) {
                    alert("로그인 정보가 일치하지 않습니다.");
            });
        },
        handleLogin() {
            this.loading = true;

            if (!this.emailCheck()) return;

            if (!this.passwordCheck()) return;
            
            this.$store.dispatch("auth/login", this.user).then(
                (res) => {

                    if (this.saveEmail){
                        this.$cookies.set('saveEmail', this.user.user_device_number);
                    }

                    if(this.user.user_device_number == 'admin@pasm.kr'){
                        this.$root.isAdmin = true;

                        //관리자인 경우 보호소 리스트를 가져와 로컬에 저장시킨다
                        this.getShelterList();

                    }else{
                        this.$root.isAdmin = false;

                    }

                    this.$root.reloadAccount();

                    this.$router.push("/");



                    // console.log(res);

                    // console.log(this.$store.state);


                },
                (error) => {

                    console.log("로그인 실패");
                    alert("계정 정보가 일치하지 않습니다.");

                }
            );
        },
        emailCheck() {
                let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

                if (exptext.test(this.user.user_device_number) == false) {
                    alert('이메일 형식이 올바르지 않습니다.');
                    this.$refs.email.focus();
                    return false;
                } else
                    return true;

        },
        passwordCheck() {
            if (this.user.user_pw.length < 6 || this.user.user_pw.length > 15) {
                alert('비밀번호가 너무 길거나 짧습니다.');
                this.$refs.password.focus();
                return false;
            } else
                return true;
        },
        rememberEmail() {
            if (!!this.saveEmail)
                this.$cookies.set('saveEmail', "");
        },
        redirectUrl(){
            // this.router.push('http://pf.kakao.com/_vxaxbUxl')
            window.open('http://pf.kakao.com/_vxaxbUxl', "_blank");
        },

        getShelterList(){
            let me = this;

            this.axios.get(`/shelter/account/list`)
            .then(function (res) {
                localStorage.setItem('shelter_list', JSON.stringify(res.data));

                me.$root.reloadAccount();
            }).catch(function (error) {
                console.log(error);
                // alert('호출 에러');
            });
        },
    },
    mounted (){
        // console.log(this.$store.state.auth.status.loggedIn);
        this.$root.loadingStop();
    },
    created () {
        if (!!this.$cookies.get('saveEmail')) {
            this.saveEmail = true;
            this.user.user_device_number = this.$cookies.get('saveEmail');
        } else {
            this.saveEmail = false;
        }
    }
    
}
</script>
<style scoped>
    @import '@/assets/css/common/common.css';
    @import '@/assets/css/style/style.css';
</style>