<template>
	<div class="container" style="margin-top : 2rem">
		<!-- Footer -->

			<!-- Copyright -->
			<div
				class="text-center p-3"
				style="border-top:1px solid #ededed;"
				>
				© 2022 Copyright: PAWINHAND
			</div>
			<!-- Copyright -->
		<!-- Footer -->
	</div>
</template>

<script>
export default {
    name: 'Footer',

}
</script>

<style scoped>
footer{
	position:fixed;
	bottom:0;
}
</style>