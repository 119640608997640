<template>
  <vue-guided-tour :steps="homeStep" :pagination="false">
      <template #content="{ stepIndex }">
          <div class="vgt__body">{{ homeStep[stepIndex].content }}</div>
      </template>
      <template #close>
          <button class="vgt__close-btn" aria-label="close Tour" @click="$vgt.end"> × </button>
      </template>
      <template #nav="{ stepIndex, isFirstStep, isLastStep }">
          <div class="vgt__nav">
              <button v-if="!isFirstStep" class="vgt__btn vgt__btn--secondary vgt__prev-btn" @click="$vgt.prev">이전</button>
              <button v-if="isLastStep" class="vgt__btn vgt__btn--primary vgt__end-btn" @click="$vgt.end">닫기</button>
              <button v-else class="vgt__btn vgt__btn--primary vgt__next-btn" @click="$vgt.next">다음{{ stepIndex }}</button>
          </div>
      </template>
  </vue-guided-tour>
  <div class="container">
    <div class="row" style="margin-top: 1.8rem;">
      <h4 class="orange_bar"></h4>
      <h4 class="fs-23 text-left mt-2" style="font-weight:700">{{shelter.city}} {{shelter.country}}</h4>
    </div>

    <div class="hstack w-100" v-if="$root.isAdmin">
        <select class="ms-auto form-select form-select-sm fr" style="max-width:100px" @change="shelterSelected($event);" v-model="admin_shelter">
            <option seletecd>보호소</option>
            <option v-for="shelter, index in $root.admin.shelterList" :value="shelter.shelter_name">{{ shelter.shelter_name }}</option>
        </select>
    </div>

    <div class="step-1">
      <div class="hstack gap-2" style="margin-bottom: 1rem; margin-top: 1.8rem; width: 100%">
        <h4 class="fs-20" >추천동물 입양 현황</h4>
          <div class="step-2 ms-auto hstack gap-2">
              <div class="hstack gap-2 text-right" > 
                <div class="form-check fr" style="margin-right : 20px">
                    <input class="form-check-input" type="checkbox" id="recommendedRecent" v-model="recommendedCheckBox" style="width:15px;height:15px;" @click="recentDateChecked('recommended')">
                    <label class="form-check-label" for="recommendedRecent">
                        올해 설정
                    </label>
                </div>
                <Datepicker 
                  style="max-width : 160px; font-size : 13px;"
                  v-model="start_date2" 
                  weekStart="0"
                  :format="format" 
                  :previewFormat="format" 
                  :dayNames="['일', '월', '화', '수', '목', '금', '토']"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  selectText="선택"
                  cancelText="취소"
                  auto-apply
                  >
                      <template #month="{ text, value }">
                          {{ value + 1 }}월
                      </template>
                      <template #month-overlay="{ text, value }">
                          {{ value + 1 }}월
                      </template>
              </Datepicker>
              <span>~</span>
              <Datepicker 
                  style="max-width : 160px; font-size : 13px !important;"
                  v-model="end_date2" 
                  weekStart="0"
                  :format="format" 
                  :previewFormat="format" 
                  :dayNames="['일', '월', '화', '수', '목', '금', '토']"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  :minDate="start_date"
                  selectText="선택"
                  cancelText="취소"
                  auto-apply
                  >
                      <template #month="{ text, value }">
                          {{ value + 1 }}월
                      </template>
                      <template #month-overlay="{ text, value }">
                          {{ value + 1 }}월
                      </template>
              </Datepicker>
              <button type="button" class="btn btn-orange" style="height : 30px; line-height: 1; min-width: 55px;" @click="updateProtectData()">검색</button>
            </div>
        </div>
      </div>
            
        
      <div class="row" style="border-top : 1px solid #ededed; border-bottom : 1px solid #ededed">
        <div class="col" style="border-right : 1px solid #ededed">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">추천동물 현황</h4>
          <div class="row position-relative">
            <p class="position-absolute top-50 start-50 translate-middle fc-orange fw-500">{{$root.convertDateFormat(start_date2, 'kor') + '\n~' + $root.convertDateFormat(end_date2, 'kor')}}<br/><span class="fs-20 fw-600">{{recommended_all_cnt}}</span> 마리</p>
            <DoughnutChart :chartData="recommendedData" :options="recommendedData.options" />
          </div>
          <div class="row" style="margin:3rem 5rem">
            <div class="col-3 text-left" v-for="i, index in 7">
              <p><i class="bi bi-square-fill" :style="{color : recommendedData.datasets[0].backgroundColor[index]}"></i> {{recommendedData.labels[index]}} {{recommendedData.datasets[0].data[index]}} </p>
            </div>
          </div>
        </div>
        <div class="col" style="border-right : 1px solid #ededed">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">입양신청 현황</h4>
          <div class="row position-relative">
            <p class="position-absolute top-50 start-50 translate-middle fc-orange fw-500">총 <span class="fs-20 fw-600">{{request_all_cnt}}</span> 건</p>
            <DoughnutChart :chartData="adoptRequestData" :options="adoptRequestData.options" />
          </div>
          <div class="row" style="margin:3rem 5rem">
            <div class="col-3 text-left" v-for="i, index in 5">
              <p><i class="bi bi-square-fill" :style="{color : adoptRequestData.datasets[0].backgroundColor[index]}"></i> {{adoptRequestData.labels[index]}} {{adoptRequestData.datasets[0].data[index]}} </p>
            </div>
          </div>
        </div>
      
      </div>

      <div class="hstack gap-2" style="margin-bottom: 1rem; margin-top: 1.8rem; width: 100%">
        <h4 class="fs-20" >보호 현황</h4>
          <div class="step-2 ms-auto hstack gap-2">
              <div class="hstack gap-2 text-right" > 
                <div class="form-check fr" style="margin-right : 20px">
                    <input class="form-check-input" type="checkbox" id="protectRecent" v-model="protectCheckBox" style="width:15px;height:15px;" @click="recentDateChecked('protect')">
                    <label class="form-check-label" for="protectRecent">
                        올해 설정
                    </label>
                </div>
                <Datepicker 
                  style="max-width : 160px; font-size : 13px;"
                  v-model="start_date" 
                  weekStart="0"
                  :format="format" 
                  :previewFormat="format" 
                  :dayNames="['일', '월', '화', '수', '목', '금', '토']"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  selectText="선택"
                  cancelText="취소"
                  auto-apply
                  >
                      <template #month="{ text, value }">
                          {{ value + 1 }}월
                      </template>
                      <template #month-overlay="{ text, value }">
                          {{ value + 1 }}월
                      </template>
              </Datepicker>
              <span>~</span>
              <Datepicker 
                  style="max-width : 160px; font-size : 13px !important;"
                  v-model="end_date" 
                  weekStart="0"
                  :format="format" 
                  :previewFormat="format" 
                  :dayNames="['일', '월', '화', '수', '목', '금', '토']"
                  :maxDate="new Date()"
                  :enableTimePicker="false"
                  :minDate="start_date"
                  selectText="선택"
                  cancelText="취소"
                  auto-apply
                  >
                      <template #month="{ text, value }">
                          {{ value + 1 }}월
                      </template>
                      <template #month-overlay="{ text, value }">
                          {{ value + 1 }}월
                      </template>
              </Datepicker>
              <button type="button" class="btn btn-orange" style="height : 30px; line-height: 1; min-width: 55px;" @click="updateProtectData()">검색</button>
            </div>
        </div>
      </div>
            
        
      <div class="row" style="border-top : 1px solid #ededed; border-bottom : 1px solid #ededed">
        <div class="col" style="border-right : 1px solid #ededed">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">상태별</h4>
          <div class="row position-relative">
            <p class="position-absolute top-50 start-50 translate-middle fc-orange">전체 두수<br/><span class="fs-20 fw-600">{{animal_all_cnt}}</span> 마리</p>
            <DoughnutChart :chartData="stateData" :options="stateData.options" />
          </div>
          <div class="row" style="margin:3rem 5rem">
            <div class="col-3 text-left" v-for="i, index in 7">
              <p><i class="bi bi-square-fill" :style="{color : stateData.datasets[0].backgroundColor[index]}"></i> {{stateData.labels[index]}} {{stateData.datasets[0].data[index]}} </p>
            </div>
          </div>
        </div>
        <div class="col">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">축종별</h4>
          <div class="row position-relative">
            <DoughnutChart :chartData="speciesData" :options="speciesData.options" />
          </div>
          <div class="row" style="margin:3rem 5rem">
            <div class="col-4" v-for="i, index in 3">
              <p><i class="bi bi-square-fill" :style="{color : speciesData.datasets[0].backgroundColor[index]}"></i> {{speciesData.labels[index]}} {{speciesData.datasets[0].data[index]}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="padding-top: 2rem; padding-bottom: 2rem; border-bottom: 1px solid #ededed;">
          <p class="fs-20" style="margin-bottom:0 !important" ><span class="fw-600">입양률</span> {{shelter.city}} 내 <span class="fw-600">{{adopt_rank}}</span>위 / <span class="fw-600">안락사율</span> {{shelter.city}} 내 <span class="fw-600">{{euth_rank}}</span>위
          </p>
          <button type="button" class="btn btn-orange" style="padding: 0px 20px !important; font-size: 17px !important; margin-left: 10px;" @click="redirectStatisticsPage()">지역별 통계보기</button>
      </div>

<!--
      <div class="hstack gap-2" style="margin-bottom: 1rem; margin-top: 3rem; width: 100%">
        <h4 class="fs-20">구조/진료 현황</h4>
          <div class="ms-auto hstack gap-2 text-right" >
            <div class="form-check fr" style="margin-right : 20px">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style="width:15px;height:15px;" v-model="treatmentCheckBox" @click="recentDateChecked('treatment')">
                <label class="form-check-label" for="flexCheckDefault">
                    이번달 설정
                </label>
            </div>
            <Datepicker 
              style="max-width : 160px; font-size : 13px;"
              v-model="start_date2" 
              weekStart="0"
              :format="format" 
              :previewFormat="format" 
              :dayNames="['일', '월', '화', '수', '목', '금', '토']"
              :maxDate="new Date()"
              :enableTimePicker="false"
              selectText="선택"
              cancelText="취소"
              auto-apply
              >
                  <template #month="{ text, value }">
                      {{ value + 1 }}월
                  </template>
                  <template #month-overlay="{ text, value }">
                      {{ value + 1 }}월
                  </template>
          </Datepicker>
          <span>~</span>
          <Datepicker 
              style="max-width : 160px; font-size : 13px !important;"
              v-model="end_date2" 
              weekStart="0"
              :format="format" 
              :previewFormat="format" 
              :dayNames="['일', '월', '화', '수', '목', '금', '토']"
              :maxDate="new Date()"
              :enableTimePicker="false"
              :minDate="start_date"
              selectText="선택"
              cancelText="취소"
              auto-apply
              >
                  <template #month="{ text, value }">
                      {{ value + 1 }}월
                  </template>
                  <template #month-overlay="{ text, value }">
                      {{ value + 1 }}월
                  </template>
          </Datepicker>
          <button type="button" class="btn btn-orange" style="height : 30px; line-height: 1; min-width: 55px;" @click="updateReportTreatmentData()">검색</button>
        </div>
      </div>
            
        
      <div class="row" style="border-top : 1px solid #ededed; border-bottom : 1px solid #ededed">
        <div class="col" style="border-right : 1px solid #ededed">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">구조 현황</h4>
          <div class="row position-relative">
            <p class="position-absolute top-50 start-50 translate-middle fc-orange">전체 신고<br/><span class="fs-20 fw-600">{{report_all_cnt}}</span> 건</p>
            <DoughnutChart :chartData="reportData" :options="reportData.options" />
          </div>
          <div class="row" style="margin:3rem 4rem">
            <div class="col-3 text-left" v-for="i, index in 4">
              <p><i class="bi bi-square-fill" :style="{color : reportData.datasets[0].backgroundColor[index]}"></i> {{reportData.labels[index]}} {{reportData.datasets[0].data[index]}} </p>
            </div>
          </div>
        </div>
        <div class="col">
          <h4 class="fs-20 mb-3" style="margin-top:2.5rem">진료 현황</h4>
          <div class="row position-relative">
            <DoughnutChart :chartData="treatmentData" :options="treatmentData.options" />
          </div>
          <div class="row" style="margin:3rem 5rem">
            <div class="col-4" v-for="i, index in treatmentData.labels.length">
              <p><i class="bi bi-square-fill" :style="{color : treatmentData.datasets[0].backgroundColor[index]}"></i> {{treatmentData.labels[index]}} {{treatmentData.datasets[0].data[index]}}</p>
            </div>
          </div>

          <div>
            <p class="fs-17 fw-500">{{$root.convertDateFormat(search2.start_date,'hyphen')}} ~ {{$root.convertDateFormat(search2.end_date, 'hyphen')}} 총 진료비 : {{all_treatment_expenses}}</p>
          </div>
        </div>
      </div>
    -->

    </div>
    <div class="step-first floating-button-layer">
      <button class="floating-button" @click="$vgt.start(1)">
        <img src="@/assets/img/question_icon.png" class="rounded-image" />
      </button>
    </div>
  </div>
</template>

<script>
import { DoughnutChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables, ChartDataLabels);

const moment = require('moment');
import { ref } from 'vue';

export default {
  name: 'HomeView',
  components: {
    DoughnutChart,
    ChartDataLabels,
  },
  data(){
    return {
      //가이드 투어
      homeStep: [
        {
          target: '.step-first',
          content: '이 곳을 누르면 해당 화면의 설명을 볼 수 있습니다.',
        },
        {
          target: '.step-1',
          content: '보호소의 통계자료를 확인할 수 있습니다.',
        },
        {
          target: '.step-2',
          content: '통계자료의 조건을 설정할 수 있습니다.',
        },
      ],

      shelter : {},

      search :{
        start_date: moment().subtract(3,'months'),
          end_date: moment(),
      },
      search2 : {
        start_date: moment('2022-01-01').toISOString(),
        end_date: moment(),
      },

      start_date: moment().subtract(3,'months').toISOString(),
      end_date: moment(),

      start_date2: moment('2022-01-01'),
      end_date2: moment(),

      recommended_all_cnt : 0,
      request_all_cnt : 0,
      animal_all_cnt : 0,
      // report_all_cnt : 0,

      adopt_rank : 0,
      euth_rank : 0,

      // all_treatment_expenses: 0,

      recommendedCheckBox : false,
      protectCheckBox : false,
      // treatmentCheckBox : false,

      backgroundColor: ['#436d9f', '#c0504d', '#9bbb59', '#8063a2', '#4aacc6', '#2d4d75', '#c0504d'],
      
      recommendedData : {
        labels: ['보호중','입양','반환','자연사','안락사','기증','방사'],
        datasets: [
          {
            data: [],
            backgroundColor: ['#436d9f', '#c0504d', '#9bbb59', '#8063a2', '#4aacc6', '#2d4d75', '#c0504d'],
          },
        ],
        options :{
          cutout: 150,
          tooltip: { enabled: false },
          hover: { mode: null },
          events: [],
          plugins: {

            legend: {
              display: false,
              // position: "bottom",
              // labels: {

              //   boxWidth: 15,
              //   boxHeight: 15,

              //   padding: 20,

              //   afterFit : function() {
              //     this.height = this.height + 500;
              //   }
                

              // },


            },
            title: {
              display: false,
              text: "상태별",
            },

            datalabels: {
              color: "#000",
              backgroundColor : '#fff',
              borderRadius : 5,
              offset : -50,
              align : 'end',

              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            },
          },

          
        },

        
      }, 
      adoptRequestData : {
        labels: ['입양','상담중','대기중','반려','취소'],
        
        datasets: [
          {
            data: [],
            backgroundColor: ['#f6951c', '#0d6efd', '#ffc107', '#a9a9a9', '#ededed'],
          },
        ],
        options :{
          cutout: 150,
          tooltip: { enabled: false },
          hover: { mode: null },
          events: [],
          plugins: {

            legend: {
              display: false,
              // position: "bottom",
              // labels: {

              //   boxWidth: 15,
              //   boxHeight: 15,

              //   padding: 20,

              //   afterFit : function() {
              //     this.height = this.height + 500;
              //   }
                

              // },


            },
            title: {
              display: false,
              text: "상태별",
            },

            datalabels: {
              color: "#000",
              backgroundColor : '#fff',
              borderRadius : 5,
              offset : -50,
              align : 'end',

              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            },
          },

          
        },
        
      },

      stateData : {
        labels: ['보호중','입양','반환','자연사','안락사','기증','방사'],
        datasets: [
          {
            data: [],
            backgroundColor: ['#436d9f', '#c0504d', '#9bbb59', '#8063a2', '#4aacc6', '#2d4d75', '#c0504d'],
          },
        ],
        options :{
          cutout: 150,
          tooltip: { enabled: false },
          hover: { mode: null },
          events: [],
          plugins: {

            legend: {
              display: false,
              // position: "bottom",
              // labels: {

              //   boxWidth: 15,
              //   boxHeight: 15,

              //   padding: 20,

              //   afterFit : function() {
              //     this.height = this.height + 500;
              //   }
                

              // },


            },
            title: {
              display: false,
              text: "상태별",
            },

            datalabels: {
              color: "#000",
              backgroundColor : '#fff',
              borderRadius : 5,
              offset : -50,
              align : 'end',

              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            },
          },

          
        },

        
      }, 
      speciesData : {
        labels: ['개','고양이','기타'],
        
        datasets: [
          {
            data: [],
            backgroundColor: ['#436d9f', '#c0504d', '#9bbb59'],
          },
        ],
        options :{
          events: [],
          cutout: 0,
          plugins: {
            legend: {
              display: false,
              position: "bottom",
            },
            title: {
              display: false,
              text: "축종별",
            },
            datalabels: {
              color: "white",
              textAlign: "center",
              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            }
          },
        }
        
      },
      /*
      reportData : {
        labels: ['접수','구조','미구조','보호자 인계'],
        
        datasets: [
          {
            data: [],
            backgroundColor: ['#436d9f', '#c0504d', '#9bbb59', '#8063a2'],
          },
        ],
        options :{
          events: [],
          cutout: 150,
          tooltip: { enabled: false },
          hover: { mode: null },
          events: [],
          plugins: {

            legend: {
              display: false,

            },
            title: {
              display: false,
              text: "상태별",
            },

            datalabels: {
              color: "#000",
              backgroundColor : '#fff',
              borderRadius : 5,
              offset : -50,
              align : 'end',
              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            }
          },
        }
        
      },
      treatmentData : {
        labels: [],
        
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        options :{
          events: [],
          cutout: 150,
          tooltip: { enabled: false },
          hover: { mode: null },
          events: [],
          plugins: {

            legend: {
              display: false,

            },
            title: {
              display: false,
              text: "진료현황",
            },

            datalabels: {
              color: "#000",
              backgroundColor : '#fff',
              borderRadius : 5,
              offset : -50,
              align : 'end',
              formatter: function(value, context) {

                let all_cnt = 0;
                for(let i = 0 ; i < context.dataset.data.length ; i++){
                  all_cnt += context.dataset.data[i];
                }

                if(context.dataset.data[context.dataIndex] != 0){
                  return context.chart.legend.legendItems[context.dataIndex].text + ' ' +((context.dataset.data[context.dataIndex]/all_cnt) * 100).toFixed(0) + '%';
                }else{
                  return '';
                }
              }
            }
          },
        }
        
      },
      */
    }

  },
  setup() {
    const date = ref();
    
    const handleDate = (modelData) => {
      alert(modelData);
      // do something else with the data
    }

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {

        let MyDateString = date.getFullYear() + '-' 
          + ('0' + (date.getMonth()+1)).slice(-2) + '-'
          +('0' + date.getDate()).slice(-2) + '' ;

        return `${MyDateString}`;
    }

    // const testData = {
    //   labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
    //   datasets: [
    //     {
    //       data: [30, 40, 60, 70, 5],
    //       backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
    //     },
    //   ],
    // };

    return { date, format };
  },
  methods :{

// ************************************************************** api method ************************************************************************* //

    getRecommendedStatistics(){
      let me = this;

      me.$root.loadingStart();
      this.search2.start_date = moment(this.start_date2).format('YYYY-MM-DD');
      this.search2.end_date = moment(this.end_date2).format('YYYY-MM-DD');

      let u = new URLSearchParams(this.search2).toString();

      this.axios.get(`/shelter/statistics/recommended?shelter_device_number=${this.shelter.shelter_device_number}&${u}`)
      .then(function (res) {
          // 실제 API를 요청한다/
          
          me.$root.loadingStop();

          if(res.status === 200){
            let result = res.data;

            if(result){
              me.recommended_all_cnt = result.all_cnt;

              me.recommendedData.datasets[0].data = [];

              me.recommendedData.datasets[0].data.push(result.protect_cnt); //보호중
              me.recommendedData.datasets[0].data.push(result.adopt_cnt); //입양
              me.recommendedData.datasets[0].data.push(result.return_cnt); //반환
              me.recommendedData.datasets[0].data.push(result.natural_cnt); //자연사
              me.recommendedData.datasets[0].data.push(result.euthanasia_cnt); //안락사
              me.recommendedData.datasets[0].data.push(result.contribute_cnt); //기증
              me.recommendedData.datasets[0].data.push(result.radiate_cnt); //방사

            }
          }else{
            alert("통계를 불러오지 못했습니다.");
          }
          
      }).catch(function (error) {
          console.log(error);
          // alert('호출 에러');
          me.$root.loadingStop();
      });
    },  

    getAdoptRequestStatistics(){
      let me = this;

      me.$root.loadingStart();
      this.search2.start_date = moment(this.start_date2).format('YYYY-MM-DD');
      this.search2.end_date = moment(this.end_date2).format('YYYY-MM-DD');

      let u = new URLSearchParams(this.search2).toString();

      this.axios.get(`/shelter/statistics/adoptrequest?shelter_device_number=${this.shelter.shelter_device_number}&${u}`)
      .then(function (res) {
          // 실제 API를 요청한다/
          
          me.$root.loadingStop();

          if(res.status === 200){
            let result = res.data;

            if(result){

              me.adoptRequestData.datasets[0].data = [];

              me.request_all_cnt = result.all_cnt;

              me.adoptRequestData.datasets[0].data.push(result.adopt_cnt); //
              me.adoptRequestData.datasets[0].data.push(result.connect_cnt); //
              me.adoptRequestData.datasets[0].data.push(result.wait_cnt); //
              me.adoptRequestData.datasets[0].data.push(result.reject_cnt); //
              me.adoptRequestData.datasets[0].data.push(result.cancel_cnt); //

            }
          }else{
            alert("통계를 불러오지 못했습니다.");
          }
          
      }).catch(function (error) {
          console.log(error);
          // alert('호출 에러');
          me.$root.loadingStop();
      });
    },  

    getAnimalStatistics(){
        let me = this;

        me.$root.loadingStart();
        this.search.start_date = moment(this.start_date).format('YYYYMMDD');
        this.search.end_date = moment(this.end_date).format('YYYYMMDD');

        let u = new URLSearchParams(this.search).toString();

        this.axios.get(`/shelter/statistics/animal?shelter_country=${this.shelter.country}&${u}`)
        .then(function (res) {
            // 실제 API를 요청한다/
            
            me.$root.loadingStop();

            if(res.status === 200){
              let result = res.data;

              if(result){
                me.animal_all_cnt = result.all_cnt;

                // me.stateData.labels = [];
                me.stateData.datasets[0].data = [];

                // me.speciesData.labels = [];
                me.speciesData.datasets[0].data = [];

                // me.stateData.labels.push('보호중 ' + result.protect_cnt);
                // me.stateData.labels.push('입양 ' + result.adopt_cnt);
                // me.stateData.labels.push('반환 ' + result.return_cnt);
                // me.stateData.labels.push('자연사 ' + result.natural_cnt);
                // me.stateData.labels.push('안락사 ' + result.euthanasia_cnt);
                // me.stateData.labels.push('반환 ' + result.contribute_cnt);
                // me.stateData.labels.push('방사 ' + result.radiate_cnt);

                me.stateData.datasets[0].data.push(result.protect_cnt); //보호중
                me.stateData.datasets[0].data.push(result.adopt_cnt); //입양
                me.stateData.datasets[0].data.push(result.return_cnt); //반환
                me.stateData.datasets[0].data.push(result.natural_cnt); //자연사
                me.stateData.datasets[0].data.push(result.euthanasia_cnt); //안락사
                me.stateData.datasets[0].data.push(result.contribute_cnt); //기증
                me.stateData.datasets[0].data.push(result.radiate_cnt); //방사

                // me.speciesData.labels.push('개 ' + result.dog_cnt);
                // me.speciesData.labels.push('고양이 ' + result.cat_cnt);
                // me.speciesData.labels.push('기타 ' + result.other_cnt);

                me.speciesData.datasets[0].data.push(result.dog_cnt); //보호중
                me.speciesData.datasets[0].data.push(result.cat_cnt); //보호중
                me.speciesData.datasets[0].data.push(result.other_cnt); //보호중
              }
            }else{
              alert("통계를 불러오지 못했습니다.");
            }
            
        }).catch(function (error) {
            console.log(error);
            // alert('호출 에러');
            me.$root.loadingStop();
        });
    },
    getAdoptRankByCity(){
        let me = this;

        this.search.start_date = moment(this.start_date).format('YYYYMMDD');
        this.search.end_date = moment(this.end_date).format('YYYYMMDD');

        let u = new URLSearchParams(this.search).toString();

        this.adopt_rank = 0

        // this.axios.get(process.env.VUE_APP_PUBLIC_API_URL + `/shelter/report?offset=${this.page.pageNum}&limit=${this.page.pageSize}`)
        this.axios.get(`/shelter/statistics/rank?city=${this.shelter.city}&type=종료(입양)&${u}`)
        .then(function (res) {
            // 실제 API를 요청한다/
            
            // me.reportList = res.data;

            if(res.status === 200){ 

              let result = res.data;
              // console.log(result)

              // console.log(result);

              if(result.length > 0){
                for(let i = 0 ; i < result.length ; i ++){
                  if(me.shelter.country == result[i].country){
                    me.adopt_rank = i+1;
                  }
                }
              }

            }else{
              alert("통계를 불러오지 못했습니다.");
            }
        }).catch(function (error) {
            console.log(error);
            // alert('호출 에러');
        });
    },
    getEuthRankByCity(){
        let me = this;

        this.search.start_date = moment(this.start_date).format('YYYYMMDD');
        this.search.end_date = moment(this.end_date).format('YYYYMMDD');

        let u = new URLSearchParams(this.search).toString();

        this.euth_rank=0;

        // this.axios.get(process.env.VUE_APP_PUBLIC_API_URL + `/shelter/report?offset=${this.page.pageNum}&limit=${this.page.pageSize}`)
        this.axios.get(`/shelter/statistics/rank?city=${this.shelter.city}&type=종료(안락사)&${u}`)
        .then(function (res) {
            // 실제 API를 요청한다/
            
            // me.reportList = res.data; 

            let result = res.data;

            // console.log(result)

            if(result.length > 0){
              for(let i = 0 ; i < result.length ; i ++){
                if(me.shelter.country == result[i].country){
                  me.euth_rank = i+1;
                }
              }
            }

        }).catch(function (error) {
            console.log(error);
            // alert('호출 에러');
        });
    },
    /*
    getReportStatistics(){
      let me = this;

      this.search2.start_date = moment(this.start_date2).format('YYYY-MM-DD');
      this.search2.end_date = moment(this.end_date2).format('YYYY-MM-DD');

      let u = new URLSearchParams(this.search2).toString();

      this.axios.get(`/shelter/statistics/report?shelter_device_number=${this.shelter.shelter_device_number}&${u}`)
      .then(function (res) {
          // 실제 API를 요청한다/
          
          let result = res.data;

          if(result){
            me.report_all_cnt = result.all_cnt;

            me.reportData.datasets[0].data = [];

            me.reportData.datasets[0].data = [];

            me.reportData.datasets[0].data.push(result.reception_cnt); //접수
            me.reportData.datasets[0].data.push(result.rescue_cnt); //구조
            me.reportData.datasets[0].data.push(result.nonerescue_cnt); //미구조
            me.reportData.datasets[0].data.push(result.return_cnt); //인계
          }

      }).catch(function (error) {
          console.log(error);
          // alert('호출 에러');
      });
    },
    */
    /*
    getTreatmentStatistics(){
      let me = this;

      this.search2.start_date = moment(this.start_date2).format('YYYY-MM-DD');
      this.search2.end_date = moment(this.end_date2).format('YYYY-MM-DD');

      let u = new URLSearchParams(this.search2).toString();

      this.axios.get(`/shelter/treatment/statistics/?shelter_device_number=${this.shelter.shelter_device_number}&${u}`)
      .then(function (res) {
          // 실제 API를 요청한다/
          
          let result = res.data;

          if(result){

            //진료 타입의 종류를 넣어줄 배열
            let result_name_arr = [];

            //해당 타입의 카운트를 계산할 배열
            let result_cnt_arr = [];

            //총 진료비 초기화
            me.all_treatment_expenses = 0;

            let color = 0
            for(let i = 0; i < result.length; i++){
              

              // console.log(result_name_arr.includes(result[i].treatment_type_name));
              //이름이 포함되어있으면 해당 인덱스를 가져와 카운트 ++
              if(result_name_arr.includes(result[i].treatment_type_name)){
                let index = result_name_arr.indexOf(result[i].treatment_type_name);

                result_cnt_arr[index] += 1;
              }else{
                // console.log('color : ', color)
                //포함되어있지 않을 경우 이름 추가
                result_name_arr.push(result[i].treatment_type_name);
                me.treatmentData.labels.push(result[i].treatment_type_name);
                me.treatmentData.datasets[0].backgroundColor.push(me.backgroundColor[color]);
                result_cnt_arr.push(1);
                color += 1;
                
              }

              // console.log(result[i].treatment_expenses)
              me.all_treatment_expenses += parseInt(result[i].treatment_item_expenses);
            }

            me.treatmentData.datasets[0].data = result_cnt_arr;
            me.treatmentData.labels = result_name_arr;
            // me.treatmentData.datasets[0].backgroundColor =


          }


      }).catch(function (error) {
          console.log(error);
          // alert('호출 에러');
      });
    },
*/
    testDate(){

      let me = this;

      // let u = new URLSearchParams(this.search).toString();
      this.search.start_date = moment(this.start_date).format('YYYYMMDD');
      this.search.end_date = moment(this.end_date).format('YYYYMMDD');

      let city = '전 국'
      let country = '전 체'
      
      // this.axios.get(process.env.VUE_APP_PUBLIC_API_URL + `/shelter/report?offset=${this.page.pageNum}&limit=${this.page.pageSize}`)
      this.axios.get(`/statistics/ratebyregion?city=${city}&country=${country}&startdate=${this.search.start_date}&enddate=${this.search.end_date}`)
      .then(function (res) {
          // 실제 API를 요청한다/
          
          // me.reportList = res.data;

          if(res.status === 200){ 

            let result = res.data;

            console.log(result);

          }else{
            alert("통계를 불러오지 못했습니다.");
          }
      }).catch(function (error) {
          console.log(error);
          // alert('호출 에러');
      });

    },
// ************************************************************** api end ************************************************************************* //

    updateProtectData(){
      this.getAnimalStatistics();
      this.getAdoptRankByCity();
      this.getEuthRankByCity();
    },

    updateRecommendedAndRequestData(){
      this.getRecommendedStatistics();
      this.getAdoptRequestStatistics();
    },
    /*
    updateReportTreatmentData(){
      this.getReportStatistics();
      this.getTreatmentStatistics();
    },
    */
    recentDateChecked(type){
      let thisYear = moment().format('YYYY');
      let thisMonth = moment().format('MM');

      if(type=='protect'){
        if(this.protectCheckBox==false){

          this.start_date = thisYear+ '-01-01';
          this.end_date = moment()

          this.updateProtectData();
        }
        
      }else if(type=='recommended'){
        if(this.recommendedCheckBox==false){
          this.start_date2 = thisYear+ '-01-01';
          this.end_date2 = moment();

          this.updateRecommendedAndRequestData();
          // this.updateReportTreatmentData();
        }
      }
    },

    redirectStatisticsPage(){
        // this.router.push('http://pf.kakao.com/_vxaxbUxl')
        window.open('https://pawinhand.kr/service/statistics.html', "_blank");
    },

    refreshStatistics(){
      this.recommended_all_cnt = 0,
      this.request_all_cnt = 0,
      this.animal_all_cnt = 0,

      this.getRecommendedStatistics();
      this.getAdoptRequestStatistics();

      this.getAnimalStatistics();
      this.getAdoptRankByCity();
      this.getEuthRankByCity();
    },

    shelterSelected(event){

      let shelter_name = event.target.value;
      let index = event.target.selectedIndex -1;

      if(shelter_name != '보호소' && !!shelter_name){
          
          this.shelter = this.$root.admin.shelterList[index];

          this.refreshStatistics();

          this.emitter.emit('selectedShelterByAdmin', this.shelter);
          
          this.$cookies.set('adminAdoptionManageShelter', this.shelter);

      }else{
          this.requestList = [];
      }

    },


  },
  mounted(){
    this.emitter.emit('headerActiveCheck', "home");

    this.getRecommendedStatistics();
    this.getAdoptRequestStatistics();

    this.getAnimalStatistics();
    this.getAdoptRankByCity();
    this.getEuthRankByCity();
    // this.getReportStatistics();
    // this.getTreatmentStatistics();
    // this.testDate()
    // this.cachingDate();

    if (!!!this.$cookies.get('revisit')) {
        this.$cookies.set('revisit', true);

        this.$vgt.start(0)
    }

  },
  created(){
    if(!this.$store.state.auth.status.loggedIn){
      this.$router.push('/login');
    }

    this.shelter = this.$root.shelter;

    if(this.$root.isAdmin){
        if(!!this.$cookies.get('adminAdoptionManageShelter')){
            this.shelter = this.$cookies.get('adminAdoptionManageShelter');
            this.admin_shelter = this.shelter.shelter_name;

        }
    }
  },
  watch: {
      start_date (start_date) {
        //data -> msg 데이터가 변경될 때 실행
        if(this.protectCheckBox){
          this.protectCheckBox = false
        }
      },
      end_date (end_date) {
        //data -> msg 데이터가 변경될 때 실행
        if(this.protectCheckBox){
          this.protectCheckBox = false
        }
      },
      start_date2 (start_date2) {
        //data -> msg 데이터가 변경될 때 실행
        if(this.recommendedCheckBox){
          this.recommendedCheckBox = false
        }
      },
      end_date2 (end_date2) {
        //data -> msg 데이터가 변경될 때 실행
        if(this.recommendedCheckBox){
          this.recommendedCheckBox = false
        }
      },
  }
}
</script>
<style scoped>
  .orange_bar::after{
    content: "";
    display: block;
    width: 5rem;
    height: 1rem;
    border-radius: 1.5625vw;
    background-image: linear-gradient(270deg, #f77706, #f5c45a);
  }

</style>