import AuthService from '../services/auth.service';

const auth_token = JSON.parse(localStorage.getItem('auth_token'));
const initialState = auth_token
  ? { status: { loggedIn: true }, auth_token ,shelterinfo : auth_token.shelterinfo, userinfo : auth_token.userinfo}
  : { status: { loggedIn: false }, auth_token: null ,shelterinfo : null, userinfo: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {

      return AuthService.login(user).then(
        auth_token => {

          if(auth_token.result == 'success'){
            commit('loginSuccess', auth_token);
            return Promise.resolve(auth_token);
          }
          
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, auth_token) {
      state.status.loggedIn = true;
      state.userinfo = auth_token.userinfo;
      state.shelterinfo = auth_token.shelterinfo;
      
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.userinfo = null;
      state.shelterinfo = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.userinfo = null;
      state.shelterinfo = null;

    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};