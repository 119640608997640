import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from 'vue3-cookies'
import mitt from 'mitt'

const { cookies } = useCookies();

const emitter = mitt();

const routes = [
  {
    path: '',
    name: '',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/account/Login.vue')
  },
  {
    path: '/animal',
    name: 'animal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/animal/AnimalManage.vue')
  },
  {
    path: '/report',
    name: 'report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/report/ReportManage.vue')
  },
  {
    path: '/board',
    name: 'board',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/board/ShelterStoryManage.vue')
  },
  {
    path: '/treatment',
    name: 'treatment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/treatment/AnimalTreatmentManage.vue')
  },
  {
    path: '/treatment/item',
    name: 'treatmentItemManage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/treatment/ItemManageMain.vue')
  },
  {
    path: '/adoption',
    name: 'adoption',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/adoption/AdoptionManageMain.vue')
  },
  {
    path: '/chat/:chat_room_idx',
    name: 'chat_detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/management/chat/ChatDetail.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/setting/SettingMain.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/*
export default function(store) {
  const router = createRouter({
    history: createWebHistory(),
    routes
  })
  router.beforeEach(async (to, from, next) => {
    if (import.meta.env.VITE_IS_LOGIN === 'Y') {
      const access = cookies.get('accessToken');
      const refresh = cookies.get('refreshToken');

      //@@ refreshToken이 없을 경우 로그인 창 띄우기
      if (refresh === null) {
        console.warn('need login...');
        store.commit('auth/needLogin', true);
      } else if (access === null && refresh !== null) { 
        //refreshToken은 있고 accessToken만 있을 경우 재발급요청
        await store.dispatch('auth/refreshToken');
      }else {
        //토큰이 다 있다면 페이지 이동 전 토큰 검증
        await store.dispatch('auth/verifyToken');
      }
      return next();
    } else {
      store.commit('auth/needLogin', false);
      return next()
    }
  })
  return router;
}
*/

export default router;

