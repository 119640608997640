<template>
  <!-- <div v-if="$store.state.auth.status.loggedIn"> -->
    <!-- 추가된 헤더 컴포넌트 사용 -->

    <!-- {{$store.state.auth.shelterinfo}} -->
    <Header v-if="$store.state.auth.status.loggedIn"></Header>
    <div style="min-height:83vh;">
      <router-view/>
    </div>
    <!-- 추가된 풋터 컴포넌트 사용 -->
    <Footer></Footer>
  <!-- </div> -->
  <!-- <LoginView v-else/> -->
  <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '20vw'}"></ConfirmDialog>

  <loading 
        :active="isLoading" 
        :can-cancel="true" 
        :color="'#f6951c'"
        :loader="'bars'"
        :z-index="9999"
        ></loading>
</template>

<script>

// @ is an alias to /src
// import ShelterLogin from '@/components/ShelterLogin.vue'
import Header from './components/common/Header.vue'; //import 헤더 추가
import Footer from './components/common/Footer.vue'; //import 풋터 추가
import HomeView from './views/HomeView';
import LoginView from '@/views/account/Login.vue';
import ConfirmDialog from 'primevue/confirmdialog';
// import html2canvas from 'html2canvas'

// Import the method.
import Loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import moment from 'moment';

// import 'bootstrap/dist/js/bootstrap.esm.min.js'

// import 'bootstrap/dist/js/bootstrap';
// import 'bootstrap/dist/css/bootstrap.css'

export default {
  name: 'HomeView',

  components: {
    Header, Footer, HomeView, LoginView, ConfirmDialog, Loading,
  },
  data() {
    return {
      image_server : process.env.VUE_APP_IMAGE_SERVER,
      video_server : process.env.VUE_APP_VIDEO_SERVER,

      shelter : {},
      ip : "",

      isLogin : false,
      isLoading : false,

      isAdmin : false,

      //관리자 메뉴
      admin : {
        shelterList : [],
      },
    }
  },
  methods: {
    showToastMsg(icon, title, msg, time){
      if(time == 'default' | time == ''){
        time = 5000;
      }

      this.$toast.show(
          "<div class='row'>" +
              "<div class='col-2' style='display: grid;align-items: center;'>" +
                  "<i class='bi bi-check-circle-fill' style='color : #198754; font-size: 1.4rem;'></i>" +
              "</div>" +
              "<div class='col-10' style='padding:0'>" +
                  "<div class='row'><p class='mb-0' style='font-size:12px;color : #000; font-weight:600;'>"+ title +"</p></div>" +
                  "<div class='row'><p class='mb-0' style='font-size:12px;color : #333;'>"+ msg +"</p></div>" +
              "</div>" +
          "</idv>",
          {
              position:"top-right",
              duration:time,
          });
    },
    toHex(str) {
        let result = '';
        for (let i=0; i<str.length; i++) {
            result += str.charCodeAt(i).toString(16);
        }
        return result;
    },
    convertFormData(data){
        const json = JSON.stringify(data);
        const blob = new Blob([json], {
            type: 'application/json'
        });

        return blob;
    },
    encodeFormData(formData) {
      const params = new URLSearchParams();
      for (const pair of formData.entries()) {
        params.append(pair[0], pair[1]);
      }
      return params.toString();
    },
    showImage(image_url){
    

        if(image_url == "profile_default.jpg"){
            return require("@/assets/img/default_profile.png");
        }else{
            return this.image_server + image_url;
        }

    },

    showVideo(video_url){
        return this.video_server + video_url;

    },

    loadingStart(){
      this.isLoading = true;
    },
    loadingStop(){
      this.isLoading = false;
    },

    modalCloseAlert(modalId){
        let result = confirm('종료하시겠습니까? 작성중이던 데이터는 저장되지 않습니다.');
        if(result){

            // console.log(modalId)
            document.getElementById(modalId).click();
        }else{

        }
    },
    convertTelFomat(phoneNumber) {

      phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

      let res ='';

      if(!phoneNumber) return res

      if(phoneNumber.length < 3) {
          res = phoneNumber
      }
      else {
          if(phoneNumber.substr(0, 2) =='02') {

              if(phoneNumber.length <= 5) {//02-123-5678
                  res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
              }
              else if(phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
                  res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
              }
              else if(phoneNumber.length > 9) {//02-1234-5678
                  res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
              }

          } else {
              if(phoneNumber.length < 8) {
                  res = phoneNumber
              }
              else if(phoneNumber.length == 8)
              {
                  res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
              }
              else if(phoneNumber.length == 9)
              {
                  res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
              }
              else if(phoneNumber.length == 10)
              {
                  res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
              }
              else if(phoneNumber.length > 10) { //010-1234-5678
                  res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
              }
          }
      }
              
      return res;

    },

    convertDateFormat(date, type){
      switch(type){
        case 'none' : return moment(date).format('YYYYMMDD');
        case 'hyphen' : return moment(date).format('YYYY-MM-DD');
        case 'dot' : return moment(date).format('YYYY.MM.DD');
        case 'kor' : return moment(date).format('YYYY년 MM월 DD일');
        case 'date' : return moment(date).format('A hh:mm');
        default : return date;
      }
    },

    replaceLineBr(text) {
      if(text){
      	return text.replace("\n", "<br/>");
      }
	  },

    convertShortCity(city){
      switch(city){
        case "서울특별시" : return "서울";
        case "부산광역시" : return "부산";
        case "대구광역시" : return "대구";
        case "인천광역시" : return "인천";
        case "광주광역시" : return "광주";
        case "세종특별자치시" : return "세종";
        case "대전광역시" : return "대전";
        case "울산광역시" : return "울산";
        case "경기도" : return "경기";
        case "강원도" : return "강원";
        case "충청북도" : return "충북";
        case "충청남도" : return "충남";
        case "전라북도" : return "전북";
        case "전라남도" : return "전남";
        case "경상북도" : return "경북";
        case "경상남도" : return "경남";
        case "제주특별자치도" : return "제주";
        default : return city;
      }
    },
    convertShortCountry(country){
      if(country.length >= 3){
        if(country.indexOf('시') || country.indexOf('군') || country.indexOf('구')){

          if(country == '세종특별자치시' || country == '제주특별자치도' || country == '창원마산합포회원구' || country == '창원의창성산구' || country == '창원진해구'){
            country = country.substr(0, 2);

            return country;
          }
          country = country.slice(0, -1);

          return country;
        }
      }else{
        return country;
      }
    },
    
    resizeImage(file) {

      let maxWidth = 800;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function(event) {
          const img = new Image();

          img.onload = function() {
            if (img.width <= maxWidth) {
              // 이미지 폭이 maxWidth보다 작으면 원본 이미지를 반환합니다.
              resolve(file);
              return;
            }

            const aspectRatio = img.width / img.height;
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const newWidth = maxWidth;
            
            const newHeight = newWidth / aspectRatio;

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            canvas.toBlob(
              blob => {
                resolve(new File([blob], file.name, { type: file.type }));
              },
              file.type,
              0.8 // 이미지 품질, 0.8은 80% 품질을 의미합니다.
            );
          };

          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      });
    },

    reloadAccount(){
      // if(!this.$store.state.auth.shelterinfo){
        const auth_token = JSON.parse(localStorage.getItem('auth_token'));

        if(auth_token != null){
          this.shelter = auth_token.shelterinfo;

          if(this.shelter.shelter_device_number == 'admin@pasm.kr'){
            console.log("어드민");

            this.isAdmin = true;

            this.admin.shelterList = JSON.parse(localStorage.getItem('shelter_list'));
          }else{
            this.isAdmin = false;
          }
        }else{

        }
        // console.log("local : " + this.shelter);
      // }
      // else{
      //   this.shelter = this.$store.state.auth.shelterinfo;

      //   console.log("store : " + this.shelter);
      // }


    },

    insertAccessLog(type, page_name, processing_information, download_reason){
        let me = this;
        let log = {};

        log.shelter = this.shelter.shelter_device_number;
        log.type = type;
        log.page_name = page_name;
        log.download_reason = download_reason;
        log.processing_information = processing_information;

        // console.log(log.ip);

        let config = {
            "headers": {
                "Content-Type": "multipart/form-data",
            }
        };

        const formData = new FormData();

        formData.append("pasmAccessLog", this.$root.convertFormData(log));

        this.axios.post('/log', formData, config)
            .then(function (res) {

            }).catch(function (error) {
                me.$root.loadingStop();

                console.log(error);
                alert('등록 에러');
        });
    },

    checkMobile() {
        var userAgent = navigator.userAgent;
        var mobile = /(iPhone|iPad|Android|BlackBerry|Windows Phone)/i.test(userAgent);
        if(mobile){
          return true;
        }else{
          return false;
        }
    }

  },
  mounted(){
  },
  created (){
    if(!this.$store.state.auth.status.loggedIn){
      this.$router.push('/login');
    }

    this.reloadAccount();
  },
  computed(){

  }
  
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
  }
}

.c-toast{
    color : #333 !important;
    padding: 0.5em 1rem !important;
    background-color: #ffffff !important;
    border : 1px solid #a9a9a9;
    box-shadow: 1px 1px 1px #ededed;
    min-width: 20%;
    min-height: 4em !important;
}
.p-dialog .p-dialog-footer {
  border-top : 0 !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
