import { createApp } from 'vue'
import App from './App.vue'

//통신
// import axios from 'axios'
import store from './store';
import router from './router';
import axios from './plugins/axios'
import axiosInterceptor from './plugins/interceptor'
axiosInterceptor(store);

import firebase from './firebase'

//부트스트랩
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "bootstrap";
import '@popperjs/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-icons/font/bootstrap-icons.css'

//컴포넌트
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'   
import Toaster from "@meforma/vue-toaster";
import VueSimpleAlert from "vue3-simple-alert-next";
import mitt from 'mitt'
import VueCookies from "vue3-cookies"
import PrimeVue from "primevue/config";
import ConfirmationService from 'primevue/confirmationservice';
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import VueHtml2pdf from 'vue3-html2pdf' //html to pdf
import { VueSignaturePad } from "vue-signature-pad";    //전자서명
import VueQrcode from '@chenfengyuan/vue-qrcode'; //큐알
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling   //툴팁

import vueEsign from 'vue-esign'

//이미지 뷰어

import "viewerjs/dist/viewer.css";
import VueViewer from 'v-viewer'

//가이드 투어
import VueGuidedTour from 'vue-guided-tour'
import 'vue-guided-tour/style.css'
//여기서 firebaseConfig 다른 곳에서 불러와야 되니까 위에서 import도 해줘야한다. 
// firebase.initializeApp(firebaseConfig)

//$firebase 는 firebase.js 를 전역적으로 쓸 수 있게 프로토타입으로 만들어  줄 것이다.
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'


const emitter = mitt();

const app = createApp(App)
app.config.globalProperties.axios = axios;
app.config.globalProperties.emitter = emitter

app.component('Datepicker', Datepicker);
app.component("VueSignaturePad", VueSignaturePad);
app.component(VueQrcode.name, VueQrcode);
app.provide('$firebase', firebase); 

app
.use(router)
.use(store)
.use(Toaster)
.use(VueSimpleAlert)
.use(ConfirmationService)
.use(PrimeVue)
.use(VueCookies,{
    expireTimes: "7d",
    secure: true,
})
.use(VueSignaturePad)
.use(vueEsign)
.use(VueHtml2pdf)
.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'top',
        allowHTML: true,
      }, // => Global default options * see all props
    }
  )
app.use(VueViewer, {
    defaultOptions: {
        title: false,
        navbar : false,
        fullscreen:false,
    }
})
app.use(VueGuidedTour)
// .use(VueMoment, moment)
// .use(cors())
app.use(Vue3VideoPlayer, {
  lang: 'en'
})
.mount('#app')
