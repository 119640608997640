
import { createStore } from "vuex";
import { auth } from "./auth.module";

const store = createStore({
  modules: {
    auth,
  },
  state: {
    dog_breeds: [
      "상세품종",
      "골든리트리버",
      "그레이하운드",
      "그레이트덴",
      "그레이트피레니즈",
      "기타",
      "꼬똥드뚤레아",
      "네오폴리탄마스티프",
      "노리치테리어",
      "뉴펀들랜드",
      "닥스훈트",
      "달마시안",
      "댄디딘몬트테리어",
      "도고까니리오",
      "도고아르젠티노",
      "도고아르젠티노",
      "도베르만",
      "도사",
      "동경견",
      "라브라도리트리버",
      "라사압소",
      "라이카",
      "래빗닥스훈드",
      "랫테리어",
      "레이크랜드테리어",
      "로디지안리즈백",
      "로트바일러",
      "롯트와일러",
      "마리노이즈",
      "마스티프",
      "말라뮤트",
      "말티즈",
      "맨체스터테리어",
      "미니어쳐닥스훈트",
      "미니어쳐불테리어",
      "미니어쳐슈나우저",
      "미니어쳐푸들",
      "미니어쳐핀셔",
      "미디엄푸들",
      "미텔스피츠",
      "믹스견",
      "바센지",
      "바셋하운드",
      "버니즈마운틴독",
      "베들링턴테리어",
      "벨기에그로넨달",
      "벨기에쉽독",
      "벨기에테뷰런",
      "벨지안셰퍼드독",
      "보더콜리",
      "보르조이",
      "보스턴테리어",
      "복서",
      "부비에데플랑드르",
      "불테리어",
      "불독",
      "브뤼셀그리펀",
      "브리타니스파니엘",
      "블랙테리어",
      "비글",
      "비숑프리제",
      "비어디드콜리",
      "비즐라",
      "빠삐용",
      "사모예드",
      "살루키",
      "삽살개",
      "샤페이",
      "세인트버나드",
      "센트럴아시안오브차카",
      "셔틀랜드쉽독",
      "셰퍼드",
      "슈나우져",
      "스코티쉬테리어",
      "스코티시디어하운드",
      "스탠다드푸들",
      "스테포드셔불테리어",
      "스피츠",
      "시바",
      "시베리안허스키",
      "시베리안라이카",
      "시잉프랑세즈",
      "시츄",
      "시코쿠",
      "실리햄테리어",
      "실키테리어",
      "아메리칸불독",
      "아메리칸아키다",
      "아메리칸에스키모",
      "아메리칸코카스파니엘",
      "아메리칸핏불테리어",
      "아메리칸불리",
      "아이리쉬세터",
      "아이리쉬울프하운드",
      "아이리쉬소프트코튼휘튼테리어",
      "아키다",
      "아프간하운드",
      "알라스칸말라뮤트",
      "에어델테리어",
      "오브차카",
      "오스트랄리안셰퍼드독",
      "오스트랄리안캐틀독",
      "올드잉글리쉬불독",
      "올드잉글리쉬쉽독",
      "와이마라너",
      "와이어폭스테리어",
      "요크셔테리어",
      "울프독",
      "웨스트하이랜드화이트테리어",
      "웰시코기카디건",
      "웰시코기펨브로크",
      "웰시테리어",
      "이탈리안그레이하운드",
      "잉글리쉬세터",
      "잉글리쉬스프링거스파니엘",
      "잉글리쉬코카스파니엘",
      "잉글리쉬포인터",
      "자이언트슈나우져",
      "재패니즈스피츠",
      "잭러셀테리어",
      "저먼셰퍼드독",
      "저먼와이어헤어드포인터",
      "저먼포인터",
      "제주개",
      "제페니즈칭",
      "진도견",
      "차우차우",
      "차이니즈크레스티드독",
      "치와와",
      "카레리안베어독",
      "카이훗",
      "캐니스팬더",
      "캐벌리어킹찰스스파니엘",
      "케리블루테리어",
      "케인코르소",
      "코리아트라이하운드",
      "코리안마스티프",
      "코카스파니엘",
      "코카푸",
      "코카시안오브차카",
      "콜리",
      "클라인스피츠",
      "키슈",
      "키스훈드",
      "토이맨체스터테리어",
      "토이푸들",
      "티베탄마스티프",
      "파슨러셀테리어",
      "팔렌",
      "퍼그",
      "페키니즈",
      "페터데일테리어",
      "포메라니안",
      "포인터",
      "폭스테리어",
      "푸들",
      "풀리",
      "풍산견",
      "프레사까나리오",
      "프렌치불독",
      "프렌치브리타니",
      "플랫코티드리트리버",
      "플롯하운드",
      "피레니안마운틴독",
      "필라브라질레오",
      "핏불테리어",
      "화이트리트리버",
      "화이트테리어",
      "휘펫",
    ], // 강아지 품종 데이터
    cat_breeds: [
      "상세품종",
      "미분류",
      "기타",
      "노르웨이숲",
      "데본렉스",
      "러시안블루",
      "레그돌-라가머핀",
      "맹크스",
      "먼치킨",
      "메인쿤",
      "발리네즈",
      "버만",
      "벵갈",
      "봄베이",
      "브리티시쇼트헤어",
      "사바나캣",
      "샤트룩스",
      "샴",
      "셀커크렉스",
      "소말리",
      "스노우슈",
      "스코티시폴드",
      "스핑크스",
      "시베리안포레스트",
      "싱가퓨라",
      "아메리칸숏헤어",
      "아비시니안",
      "재패니즈밥테일",
      "터키시앙고라",
      "통니키즈",
      "페르시안-페르시안친칠라",
      "하바나브라운",
      "하일랜드폴드",
      "코리안숏헤어",
    ],
  },
  getters: {
    getDog_breeds: state => state.dog_breeds,
    getCat_breeds: state => state.cat_breeds
  },
});

export default store;