<template>
	<!-- <nav class="nav">
            <div class="hstack w-100">
                <div class="text-left ml-2">
                    <router-link class="navbar-brand" to="/" @click="handleNavClick(links[0])">
                        <img :src="require('@/assets/pawinhand_logo_v2.png')" class="img-logo" style="width : 50%"/>
                    </router-link>
                </div>
                <div class="ms-auto" id="open-navbar1" style="margin-top:1.3rem">
                    <ul class="list">
                        <li v-for="menu in links"><router-link class="nav-link" :class="menu.isActive ? 'active' : ''" :to='menu.to' @click="handleNavClick(menu)">{{menu.title}}</router-link></li>
                        <li style="margin-left: 4rem;"><router-link class="nav-link" to='/setting' @click="deactivateMenu()"><i class="bi bi-gear" style="color:#333; font-size: 1.1rem; "></i></router-link></li>
                        <li style="">
                            <router-link class="nav-link" to=''>
                                <button type="button" class="btn position-relative" id="alarmButton" data-bs-toggle="dropdown" aria-expanded="false" style="padding:0 0.6rem 0 0 !important">
                                    <i class="bi bi-bell" style="color:#333; font-size: 1.1rem;"></i>
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="alarmCount > 0">
                                        {{alarmCount}}
                                        <span class="visually-hidden">unread messages</span>
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="alarmButton" v-if="alarmCount > 0">
                                    <li ><a class="dropdown-item" href="#">알람 1</a></li>
                                </ul>
                                <ul class="dropdown-menu" aria-labelledby="alarmButton" v-else>
                                    <li class="dropdown-item">읽지않은 알림이 없습니다.</li>
                                </ul>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->
    <div class="w-100" style="background-color: #ffffff; border-bottom: 1px solid #ececec;">
        <header class="container">
            

            <nav class="main-navbar">
                <div class="navbar__logo align-self-center" style="text-align: left;">
                    <router-link class="navbar-brand" to="/" @click="handleNavClick(links[0])">
                        <img :src="require('@/assets/pawinhand_logo_v2.png')" class="img-logo"/>
                    </router-link>
                </div>
                
                <div class="navbar__open" :class="more_clicked ? 'active' : ''">

                    <ul class ="navbar__menu align-self-center">
                        <li v-for="menu in links"><router-link :class="menu.isActive ? 'active' : ''" :to='menu.to' @click="handleNavClick(menu)">{{menu.title}}</router-link></li>
                    </ul>

                    <ul class="navbar__icons">
                        <li>
                            <router-link class="navbar-brand" to='/setting' @click="deactivateMenu(); this.more_clicked = false;" style="margin-right: 0;">
                                <button type="button" class="btn position-relative">
                                    <i class="bi bi-gear" style="color:#333; font-size: 1.1rem; "></i>
                                </button>
                            </router-link>
                        </li>
                        <li style="">
                            <router-link class="navbar-brand" to='' style="margin-right: 0;" >
                                <button type="button" class="btn position-relative" id="alarmButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-bell" style="color:#333; font-size: 1.1rem;"></i>
                                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" v-if="alarmCount > 0">
                                        {{alarmCount}}
                                        <span class="visually-hidden">unread messages</span>
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="alarmButton" v-if="alarmCount > 0">
                                    <li ><a class="dropdown-item" href="#">알람 1</a></li>
                                </ul>
                                <ul class="dropdown-menu" aria-labelledby="alarmButton" v-else>
                                    <li class="dropdown-item">읽지않은 알림이 없습니다.</li>
                                </ul>
                            </router-link>
                        </li>
                    </ul>
                    
                </div>

                <a class="navbar__toogleBtn" @click="more_clicked = !more_clicked">
                    <i class="bi bi-list"></i>    
                </a>
            </nav>  
        </header>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data(){
        return {
            links: [
                {
                    title    : '홈',
                    to       : '/',
                    isActive : false,
                },
                {
                    title    : '신고관리',
                    to       : '/report',
                    isActive : false,
                },
                {
                    title    : '동물관리',
                    to       : '/animal',
                    isActive : false,
                },
                {
                    title    : '게시글관리',
                    to       : '/board',
                    isActive : false,
                },
                {
                    title    : '진료관리',
                    to       : '/treatment',
                    isActive : false,
                },
                {
                    title    : '입양관리',
                    to       : '/adoption',
                    isActive : false,
                },
                
            ],

            alarmCount : 0,
            more_clicked : false,
        }
    },
    methods: {
        handleNavClick(item) {
            this.links.forEach(el => {
                el.isActive = false;
            });

            item.isActive = true;
            this.more_clicked = false;
        },
        deactivateMenu(){
            this.links.forEach(el => {
                el.isActive = false;
            });
        },

        headerActiveCheck(pageName){
            switch(pageName){
                case 'home' : this.links[0].isActive = true; break;
                case '' : break;
                case '' : break;
                case '' : break;
                default : break;
            }
        },
        headerActiveCheckFromUrl(){

            let url = window.location.href;

            let active;

            if(url.includes('report')){
                active = 1;
            }else if(url.includes('animal')){
                active = 2;
            }else if(url.includes('board')){
                active = 3;
            }else if(url.includes('treatment')){
                active = 4;
            }else if(url.includes('adoption')){
                active = 5;
            }

            for(let i = 0; i < 5; i++){

                if(this.links[i].isActive != undefined){
                    if(i == active){
                        this.links[i].isActive = true;
                    }else{
                        this.links[i].isActive = false;
                    }
                }
            }
        }

    },
    watch: {
        $route() {
            this.headerActiveCheckFromUrl();
        },
    },
    mounted(){

        let url = window.location.href;

        if(url.includes('report')){
            this.links[1].isActive = true;
        }else if(url.includes('animal')){
            this.links[2].isActive = true;
        }else if(url.includes('board')){
            this.links[3].isActive = true;
        }else if(url.includes('treatment')){
            this.links[4].isActive = true;
        }else if(url.includes('adoption')){
            this.links[5].isActive = true;
        }
        // else if(url.slice(-3) == 'kr/'){
        //     this.links[0].isActive = true;
        // }
        this.emitter.on('headerActiveCheck', this.headerActiveCheck);

    }

}
</script>

<style scoped>
    @import '@/assets/css/header/style.css';

    a{text-decoration:none; color:#333;}

    @media (max-width: 768px) {
        .container{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
</style>