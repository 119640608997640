import axios from 'axios';

const API_URL = process.env.VUE_APP_PUBLIC_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/login', user)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('auth_token', JSON.stringify(response.data));

          // localStorage.setItem('isLogin', true);
          return response.data;

        }
      });
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('shelter_list');
    // state.auth = null;
    // localStorage.removeItem('isLogin');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();